export const typeOptionsW = [
  {
    name: "内力",
    id: "1",
    data: [
      {
        name: "扭矩(Mx)",
        id: "str.Mx",
        text: "Mx",
        unit: "kN·m"
      },
      {
        name: "弯矩(My)",
        id: "str.My",
        text: "My",
        unit: "kN·m"
      },
      {
        name: "弯矩(Mz)",
        id: "str.Mz",
        text: "Mz",
        unit: "kN·m"
      },
      {
        name: "剪力(Qx)",
        id: "str.Fz",
        text: "Fz",
        unit: "kN"
      },
      {
        name: "剪力(Qy)",
        id: "str.Fy",
        text: "Fy",
        unit: "kN"
      },
      {
        name: "轴力(N)",
        id: "str.Fx",
        text: "Fx",
        unit: "kN"
      }
    ]
  },
  {
    name: "位移",
    id: "2",
    data: [
      {
        name: "位移(x)",
        id: "unoda0.u",
        unit: "m"
      },
      {
        name: "位移(y)",
        id: "unoda0.v",
        unit: "m"
      },
      {
        name: "位移(z)",
        id: "unoda0.w",
        unit: "m"
      },
      {
        name: "转角(x)",
        id: "unoda1.anx",
        unit: "rad"
      },
      {
        name: "转角(y)",
        id: "unoda1.any",
        unit: "rad"
      },
      {
        name: "转角(z)",
        id: "unoda1.anz",
        unit: "rad"
      }
    ]
  },
  // {
  //   name: "应力",
  //   id: "3",
  //   data: [
  //     {
  //       name: "正应力",
  //       id: "str.Mx",
  //       // unit: "Mpa"
  //       unit: "kN·m"
  //     }
  //     // {
  //     //   name: "主应力",
  //     //   id: "unoda1.any"
  //     // },
  //     // {
  //     //   name: "剪应力",
  //     //   id: "unoda1.anz"
  //     // }
  //   ]
  // }
];

export const analysisOptions = [
  {
    name: "整体温度效应",
    type: "a",
    id: "1"
  },
  {
    name: "自定义温度效应",
    type: "a",
    id: "2"
  },
  {
    name: "温度梯度",
    type: "b",
    id: "3"
  },
  {
    name: "自定义温度梯度",
    type: "b",
    id: "4"
  }
];

export const seismicOptions = [
  {
    name: "实测数据",
    id: "1"
  },
  {
    name: "人工数据",
    id: "2"
  }
];

export const DloadOptions = [
  {
    name: "C64",
    id: "1"
  },
  {
    name: "C70",
    id: "2"
  },
  {
    name: "C80",
    id: "3"
  }
];
